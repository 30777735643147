import React, { useContext } from 'react';
import { GlobalDataContext } from '../../context/context';
import { GiMeal } from 'react-icons/gi'

const BlockOne = ({btnStyle}) => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <>
        <div className='md:flex justify-center bgBloque p-10 items-center'>
            <div className='md:w-[50%] p-5'>
                <img src={rpdata?.stock?.[20]} className='rounded' alt='ImageOrder'/>
            </div>
            <div className='md:w-[50%] p-5'>
                <h2>{rpdata?.dbSlogan?.[6].slogan}</h2>
                <p className='text-white'>{rpdata?.dbAbout?.[1].text}</p>
                <a href='https://www.authorize.net/' target='_blank' rel='noreferrer' className='boton my-3 px-6 py-4 w-52 rounded-lg hover:bg-red-700' >
                    <GiMeal />&#160;Order now Here !
                </a>
            </div>
        </div>
        </>
    )
}


export default BlockOne;